.App {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.auth-form, .request-form {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.auth-form input, .request-form input, .request-form select {
  margin-bottom: 15px;
}

.auth-form button, .request-form button {
  width: 100%;
}


/* Add this CSS to your stylesheet */
.image {
  width: 100%;
  height: auto;
}

@media (min-width: 600px) {
  .image {
    width: 60%;
  }
}

@media (min-width: 900px) {
  .image {
    width: 40%;
  }
}

.badge-success {
  font-size: 1.5em; /* Increase the font size */
  color: #fff; /* Make the text color white */
  background-color: #28a745; /* Use a green background color */
  padding: 5px 10px; /* Add padding around the badge */
  border-radius: 4px; /* Add rounded corners */
}

.highlight-text {
  text-align: justify;
  font-size: 1.1em; /* Slightly smaller font size */
  font-weight: 500; /* Medium font weight */
  font-family: 'Georgia', serif; /* A more elegant font */
  color: #444; /* Softer dark color for readability */
  line-height: 1.5; /* Improved readability */
  margin: 20px; /* Margin to give it a framed look */
  padding: 15px; /* Padding for better text spacing */
  border: 1px solid #ddd; /* Light border for definition */
  border-radius: 8px; /* Rounded corners for a smooth look */
  background-color: #f9f9f9; /* Light background color */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}